body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-weight: 800;
  font-size: 4vw;
  letter-spacing: 0.15rem;
  color: white;
  margin: 0;
}

h2 {
  font-weight: 700;
  letter-spacing: 0.5rem;
  font-size: 3rem;
  color: black;
  text-align: center;
}

h3 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.22;
}

h5 {
  font-weight: 600;
}


/* This controls the reveal of the main logo */

.reveal {
  opacity: 1 !important;
}

.hide {
  opacity: 0 !important;
}

.main-logo {
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity 1s;
  background-color: rgba(0, 0, 0, 0.3);
}

.main-logo > svg > path {
  stroke: white;
  stroke-width: 2;
}

.video-container {
  position: relative;
  height: auto;
  width: 100vw;
  overflow: hidden;
}

.video-container video {
  width: 100%;
}

/* Divs and Layout */

.main {
  margin: 5rem 16rem 0 16rem;
}

.line {
  background-color: black;
  height: 2px;
  width: 100%;
  margin: 2rem 0;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6rem;
  padding: 6rem 0;
}

.grid.social {
  gap: 2rem;
  justify-content: center;
  padding: 2rem 0;
}

form.grid {
  gap: 2rem;
  padding: 0;
  flex-wrap: nowrap;
}

.grid-element {
  position: relative;
  width: 40%;
  min-width: 240px;
  margin: auto;
  text-align: center;
}

.grid-input {
  all: unset;
  width: 25%;
  height: 2rem;
  text-align: left;
  padding-left: 1rem;
  border: black solid 2px
}

button.grid-input {
  text-align: center;
  background-color: #d9d9d9;
  transition: transform 0.3s;
}

button:hover {
  transform: scale(1.1);
}

button:focus {
  background-color: white;
}

.grid-element img {
  object-fit: contain;
  width: 100%;
}

.grid-element video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
}

#info {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .video-container video {
    width: unset;
  }

  .main {
    margin: 2rem;
  }

  .grid {
    display: block;
  }

  .grid.social {
    display: flex;
  }

  .grid-input {
    display: block;
    margin: 8px auto;
    width: 90%;
  }
}